import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeArc,
    placeLogo
} from '../../../common/edliy_utils-fractions';
const Boxes = {
  box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.circle.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-0, 18, 20, -2],keepaspectratio: true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
  makeResponsive(brd1);
  brd1.options.layer['image'] =14;
//	brd1.create('text', [6, 11.5, 'Fraction'],{highlight:false, display:'internal', anchorX:'middle', anchorY:'middle', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}, fixed:true});
  placeTitle(brd1, 'Symmetric & Asymmetric Shapes', '(Tap on a shape to find out if it is symmetric)');
  placeLogo(brd1);
  //brd1.create('axis', [[-1, 0], [1, 0]], {strokeWidth: 2, strokeColor: 'red'});
  brd1.suspendUpdate();
  var name = placeMiddleText(brd1, 6.5, 14, '');
  name.setAttribute({color:'green'});
  var des = placeMiddleText(brd1, 6.5, 14, '');
  des.setAttribute({color:'green'});
  brd1.options.layer['image'] =14;
  var divider = placeImage(brd1, '/assets/symmetric-shape-1.svg', 2, 9, 3, 0);
  divider.on('down', function(){name.setText('This is a symmetric shape.');});
  divider.on('out', function(){name.setText(''); des.setText('')});
  var compass = placeImage(brd1, '/assets/symmetric-shape-2.svg', 7, 10, 3, 0);
  compass.on('down', function(){name.setText('This is a symmetric shape.'); });
  compass.on('out', function(){name.setText(''); des.setText('')});
  var pro =placeImage(brd1, '/assets/symmetric-shape-3.svg', 10, 6, 3, 0);
  pro.on('down', function(){name.setText('This is a symmetric shape.');});
  pro.on('out', function(){name.setText(''); des.setText('')});
  var ruler = placeImage(brd1, '/assets/symmetric-shape-4.svg', 12, 12,  3, 0);
  ruler.on('down', function(){name.setText('This is a symmetric shape.');});
  ruler.on('out', function(){name.setText(''); des.setText('')});
  //////////////////////////////////////////////////
  var divider = placeImage(brd1, '/assets/asymmetric-shape-1.svg', 1, 1, 3, 0);
  divider.on('down', function(){name.setText('Not symmetric!');});
  divider.on('out', function(){name.setText('');});
  var compass = placeImage(brd1, '/assets/asymmetric-shape-2.svg', 6, 4., 3, 0);
  compass.on('down', function(){name.setText('Not symmetric!');});
  compass.on('out', function(){name.setText('');});
  var pro =placeImage(brd1, '/assets/asymmetric-shape-3.svg', 13, 1, 3, 0);
  pro.on('down', function(){name.setText('Not symmetric!');});
  pro.on('out', function(){name.setText('');});
  var ruler = placeImage(brd1, '/assets/asymmetric-shape-4.svg', 15, 7,  3, 0);
  ruler.on('down', function(){name.setText('Not symmetric!');});
  ruler.on('out', function(){name.setText('');});
  /*
  var sq = placeImage(brd1, '/assets/setSquare.svg', 4, 0, 4, 0);
  sq.on('down', function(){name.setText('This is a Set Square.'); des.setText('A set square is used for drawing parallel and perpendicular lines.'); });
  sq.on('out', function(){name.setText(''); des.setText('')}); */
  //Title and subtitleplaceSlider(board, positionX, positionY, limit1, start, limit2, length, name)
/*  var radius = placeSlider(brd1, 0, -5, 1, 1.5, 2.5, 5, '&nbsp; R(m)');
  radius.setAttribute({unitLabel:'m'});
  radius.setAttribute({snapWidth:0.5});



  var go = placeImage(brd1, '/assets/go.svg', 10, -6, 2, 0);
  go.setLabel('Tap to roll the circle.')
  go.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  go.on('over', function () {this.label.setAttribute({visible:true});});
  go.on('out', function () {this.label.setAttribute({visible:false});});
  placeMiddleText(brd1, 6.5, 9, 'Circumference = Distance between A and B');
  placeMiddleText(brd1, 6.5, 13, 'L = 2πR');
  placeMiddleText(brd1, 6.5, 11, 'Select a radius for the circle. Then hit Go.');
  //var radius = brd1.create('slider',[[15,12],[20,12],[1,2.5,3]], {snapToGrid: false ,face:'circle', size:3, name:'r', strokeColor:'black', label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
  var segment1 = brd1.create('segment', [[0,function(){return radius.Value()}], [function(){return 2*Math.PI*radius.Value()},function(){return radius.Value()}]], {fixed: true, strokeColor: 'black', visible:false});

  var ptCenter = brd1.create('glider', [0,2, segment1],{face:'+' , name:'', label:{fontSize:function(){return 16*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
  var pt1 =brd1.create('point', [function(){return ptCenter.X()},function(){return ptCenter.Y()-radius.Value()}], {fixed:false, visible:false, face:'circle', size:4, name:'B', label: {offset: [0,-20]}, strokeColor: 'black', fillColor: '#e1e1e1'});
  brd1.create('point', [ptCenter.X(),ptCenter.Y()-radius.Value()], {fixed:false, visible:true, face:'circle', size:2, name:'A', label: {offset: [0,-20]}, strokeColor: 'red', fillColor: 'red'});
  ///////////////////////////////////////////////////
  var c1 = brd1.create('circle', [ptCenter, pt1], {strokeColor: 'black', strokeWidth:0.1, fillColor:'orange', fillOpacity:0.5});
  ////////////////
  go.on('down', function(){ptCenter.moveTo([2*Math.PI*radius.Value(), radius.Value()], 500)});

  radius.on('drag', function(){ptCenter.moveTo([0, radius.Value()])});
  //
  function angle1(){
      return  (2*Math.PI-ptCenter.X()/radius.Value() );
  }
//////////////////////////////////////////////////
  var pt3 =brd1.create('point', [function(){return ptCenter.X()+radius.Value()*Math.sin(angle1())},function(){return ptCenter.Y()-radius.Value()*Math.cos(angle1())}],
  {fixed:false, visible:true, face:'circle', size:2, name:'B', label: {offset: [0,-20], visible:function(){return ptCenter.X()>2*Math.PI*radius.Value()-0.1;}}, strokeColor: 'red', fillColor: 'red'});
  //pt3.label.setAttribute({visible:true});
  //placeArrow(brd1, [()=>ptCenter.X(), ()=>ptCenter.Y()], [()=>pt3.X(), ()=>pt3.Y()], 2, 'black');
  //var txt1 = brd1.create('text', [3, -4,  function(){return 'ANGLE = ' + angle1()*180/Math.PI}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);

  var segment2 = brd1.create('segment', [[0,0], [function(){return pt1.X()},0]], {fixed: true, strokeColor: 'red', visible:true});

  var arc1 = brd1.create('arc', [ptCenter, pt1, pt3], {strokeColor: 'red', strokeWidth: 2});


  //Arrow
  var ptArrow1 =brd1.create('point', [function(){return pt1.X()} ,-2], {fixed:true, visible:false, face:'circle', size:4, name:' ', label: {offset: [0,25]}, strokeColor: 'black', fillColor: '#e1e1e1'});
  var ptArrow2 =brd1.create('point', [0 ,-2], {fixed:false, visible:false, face:'circle', size:4, name:' ', label: {offset: [0,25]}, strokeColor: 'black', fillColor: '#e1e1e1'});
  var segment2 = brd1.create('segment', [ptArrow1, ptArrow2], {fixed: true, strokeColor: 'black', visible:true, strokeWidth:1,
                                                                                                                  highlightStrokeWidth: 2,
                                                                                                                  lastArrow: {type: 2, size: 8, highlightSize: 6},
                                                                                                                  touchLastPoint: false,
                                                                                                                  firstArrow: {type: 2, size: 8}
                                                                                                                  });

  var txtPer = brd1.create('text', [function(){return pt1.X()/2}, -1.5,  function(){return ''+pt1.X().toFixed(2)+' m'}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);


  var ptArrow3 =brd1.create('point', [-5,0], {fixed:true, visible:false, face:'circle', size:4, name:' ', label: {offset: [0,25]}, strokeColor: 'black', fillColor: '#e1e1e1'});
  var ptArrow4 =brd1.create('point', [-5, function(){return radius.Value()}], {fixed:false, visible:false, face:'circle', size:4, name:' ', label: {offset: [0,25]}, strokeColor: 'black', fillColor: '#e1e1e1'});
  var segment3 = brd1.create('segment', [ptArrow3, ptArrow4], {fixed: true, strokeColor: 'black', visible:true, strokeWidth:1,
                                                                                                                  highlightStrokeWidth: 2,
                                                                                                                  lastArrow: {type: 2, size: 8, highlightSize: 6},
                                                                                                                  touchLastPoint: false,
                                                                                                                  firstArrow: {type: 2, size: 8}
                                                                                                                  });

  var txtRad = brd1.create('text', [-4, function(){return radius.Value()/2},  function(){return radius.Value().toFixed(2)}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);

*/

  brd1.unsuspendUpdate();
},
}
export default Boxes;
